// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, H5 } from '@tackl';
import { h3Styles, h5Styles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 10;
    background: linear-gradient(180deg,
        ${props.theme.colors.brand.bc1} 0%,
        ${props.theme.colors.brand.bc1}00 100%);
`);

export const AnimatedLine = styled.span(props => css`
    position: absolute;
    ${props.isTop ? `top: 0;` : `bottom: 0;`}
    left: 0;
    right: 0;
    height: 1px;
    background: ${props.theme.colors.brand.bc5};
    opacity: 0.2;

    transform: scaleX(0);
    transform-origin: center left;

    ${breakup.large`
        left: calc(8.333vw + 1.8rem);
        right: calc(8.333vw + 1.8rem);
    `}
`);
export const Content = styled(Div)(props => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 3.6rem;

    ${breakup.large`
        flex-direction: row;
    `}
`);

export const Heading = styled.h2(props => css`
    ${h3Styles}

    ${breakup.large`
        ${h5Styles}
    `}
`);

export const SocialList = styled.ul(props => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2.4rem;
`);

export const SingleSocial = styled.li(props => css`

`);

export const Social = styled.a(props => css`
    position: relative;
    display: inline-block;

    width: 6rem;
    height: 6rem;
    border-radius: 6rem;

    ${breakup.large`
        width: 8.4rem;
        height: 8.4rem;
        border-radius: 8.4rem;
    `}

    &:hover {
        ${breakup.large`
            cursor: pointer;

            &:before {
                transform: scale(1);
            }

            &:after {
                transform: scale(1.4);
            }

            svg {
                &:nth-child(1) {
                    transform: translate(-50%, -50%);
                    opacity: 1;
                }

                &:nth-child(2) {
                    transform: translate(-50%, ${props.isDown ? 300 : -300}%);
                }
            }
        `}
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        border-radius: 8.4rem;

        transition: all 0.5s ${props.theme.bezzy};
    }

    /* Fill */
    &:before {
        z-index: 1;
        background: ${props.theme.colors.brand.bc5};
        transform: scale(0);
    }

    /* Outline */
    &:after {
        z-index: 2;
        border: 2px solid ${props.theme.colors.brand.bc5};
        opacity: 0.2;
    }

    svg {
        position: absolute;
        z-index: 3;
        top: 50%; left: 50%;

        display: block;
        width: 2.4rem;
        height: 2.4rem;
        fill: ${props.theme.colors.brand.bc5};
        transition: transform 0.5s ${props.theme.bezzy};


        ${breakup.large`
            width: 3.6rem;
            height: 3.6rem;
        `}

        &:nth-child(1) {
            fill: ${props.theme.colors.brand.bc1};
            transform: translate(-50%, ${props.isDown ? -400 : 400}%);
            opacity: 0;
        }

        &:nth-child(2) {
            transform: translate(-50%, -50%);
        }
    }
`);

export const Icons = styled.div(props => css`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`);