// Imports
// ------------
import React, { useRef, useEffect } from 'react';
import Icon from '@icons';
import Author from './Author';
import { Row, Column } from '@waffl';
import { observer } from 'mobx-react-lite';
import { menuState } from '@states';
import { GatsbyImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import dayjs from 'dayjs';
import ScrollButton from '@parts/ScrollButton';

// Styles
// ------------
import {
    Jacket,
    Content,
    Parallax,
    Back,
    BackLabel,
    Background,
    Date,
    Title,
    Extras,
    ReadingTime,
    ArticleDetails,
    ScrollButtonPos
} from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const NewsHero = ({ title, media, author, articleDate, latestArticle, articleBuilderItems }) => {
    // NOTE • Refs
    const trigger = useRef();
    const target = useRef();

    // NOTE ª Post Date
    const posted = dayjs(articleDate).format('DD MMM YYYY');


    // NOTE • Raed time
    const readTime = articleBuilderItems.length;

    // NOTE • Onload: scroll Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            gsap.to(target.current, {
                y: `-10%`,
                ease: 'none',
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '1% 0%',
                    end: '100% 0%',
                    scrub: 0.2,
                    markers: false,
                },
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <>
            <Jacket extraPad={menuState.heightSize} ref={trigger}>
                <Background>
                    <Parallax ref={target}>
                        <GatsbyImage image={media.data} alt={media.alt ? media.alt : "Sirio Strategies"} />
                    </Parallax>
                </Background>

                <Row isExpanded isMaxHeight>
                    <Column small={12} large={8} pushLarge={1} mpad isMaxHeight>
                        <Content>
                            <Back to="/news/">
                                <Icon type="chevron-left" />
                                <BackLabel data-label="Back to News">Back to News</BackLabel>
                            </Back>

                            <ArticleDetails>
                                <Date isFirst={latestArticle === 1}>
                                    <time>{posted}</time>
                                    {latestArticle === 1 && (<span>New</span>)}
                                </Date>
                                <Title>{title}</Title>
                                <Extras>
                                    <Author name={author.name} pic={author.pic} />

                                    {/* TODO • Hook this up */}
                                    <ReadingTime>{readTime * 2} mins read</ReadingTime>
                                </Extras>
                            </ArticleDetails>
                        </Content>
                    </Column>
                </Row>

                <ScrollButtonPos>
                    <ScrollButton isDown hash="content" />
                </ScrollButtonPos>
            </Jacket>
        </>
    );
}

export default observer(NewsHero);