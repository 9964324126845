// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Div, Em } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Div)(props => css`
    display: flex;
    align-items: center;
    gap: 1.2rem;
`);


export const Image = styled.picture(props => css`
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 100%;
    overflow: hidden;

    .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
    }
`);


export const Name = styled(Em)(props => css`
    
`);
