// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    


    .player-wrapper {
        position: relative;
        padding-top: 56.25%;
    }

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
`);
