// Imports
// ------------
import React from 'react';
import ContentBlock from './ContentBlock';
import ImageBlock from './ImageBlock';
import VideoBlock from './VideoBlock';
import QuoteBlock from './QuoteBlock';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const NewsBuilder = ({ data }) => (
    <Jacket pad id="content">
        {data.map((block) => (
            <React.Fragment key={block.uniqueId}>
                {block.type === "DatoCmsContentBlock" && (
                    <ContentBlock
                        content={block.content.value}
                    />
                )}

                {block.type === "DatoCmsImage" && (
                    <ImageBlock
                        image={block.image}
                        creditsNeeded={block.creditsNeeded}
                        credits={block.credits}
                    />
                )}

                {block.type === "DatoCmsVideo" && (
                    <VideoBlock
                        url={block.videoUrl.url}
                        thumb={block.videoUrl.thumb}
                    />
                )}

                {block.type === "DatoCmsQuote" && (
                    <QuoteBlock
                        author={block.author}
                        hasAuthor={block.hasAuthor}
                        quote={block.quote}
                    />
                )}
            </React.Fragment>
        ))}
    </Jacket>
);

export default NewsBuilder;