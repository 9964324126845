// Imports
// ------------
import React from 'react';
import { Row, Column } from '@waffl';

// Styles
// ------------
import { Jacket, Content, Quote, Author } from './styles';

// Component
// ------------
const QuoteBlock = ({ author, hasAuthor, quote }) => {
    return (
        <Jacket>
            <Row isExpanded>
                <Column small={12} medium={10} pushMedium={1} mpad>
                    <Content>
                        <Quote>&#8220;{quote}&#8221;</Quote>
                        {hasAuthor && <Author>– {author}</Author>}
                    </Content>
                </Column>
            </Row>
        </Jacket>
    );
}

export default React.memo(QuoteBlock);