// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, ContentBlock } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    
`);


export const StyledContent = styled(ContentBlock)(props => css`
    h4, h5 {
		color: ${props.theme.colors.brand.bc5};
		margin: 0 0 2.4rem;
        color: ${props.theme.colors.brand.bc5};

		${breakup.large` margin: 0 0 6rem; `}
	}

    p {
        color: ${props.theme.colors.brand.bc5o6};
    }

    ul {
        gap: 2.4rem;

        ${breakup.large`
            gap: 3.6rem;
        `}

        li {
            position: relative;
            padding-left: 3rem;

            ${breakup.large`
                padding-left: 3.6rem;
            `}

            &:before {
                content: "";
                position: absolute;
                top: 1rem;
                left: 0;
                width: 0.6rem;
                height: 0.6rem;
                background: ${props.theme.colors.brand.bc3};

                ${breakup.large`
                    top: 1.7rem;
                    width: 1.2rem;
                    height: 1.2rem;
                `}
            }
        }
    }
`);