// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';
import { type, emStyles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    
`);

export const Content = styled.figure(props => css`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    background: ${props.theme.colors.brand.bc5o1};
    padding: 3.6rem 3.6rem;

    ${breakup.large`
        gap: 6rem;
        padding: ${props.theme.space.large} 8.333vw;
    `}
`);

export const Quote = styled.blockquote(props => css`
    ${type}

    font-size: 2.2rem;
    line-height: 1.32;
    font-weight: ${props.theme.font.weight.light};
    color: ${props.theme.colors.brand.bc5};

    ${breakup.large`
        font-size: 4.8rem;
        letter-spacing: -0.1rem;
    `}
`);

export const Author = styled.figcaption(props => css`
    ${emStyles}
    color: ${props.theme.colors.brand.bc5o6};
    align-self: flex-end;
`);
