// Imports
// ------------
import React, { useRef, useEffect } from 'react';
import Icon from '@icons';
import { Row, Column } from '@waffl';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


// Styles
// ------------
import { Jacket, AnimatedLine, Content, Heading, SocialList, SingleSocial, Social, Icons } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const NewsShare = ({ fb, tw, li }) => {
    // NOTE • Refs
    const targetTop = useRef();
    const targetBottom = useRef();

    // NOTE • Onload: scroll Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            gsap.to(targetTop.current, {
                scaleX: 1,
                ease: 'none',
                scrollTrigger: {
                    trigger: targetTop.current,
                    start: '0px 100%',
                    end: '276px 100%',
                    scrub: 0.5,
                    markers: false,
                },
            });

            gsap.to(targetBottom.current, {
                scaleX: 1,
                ease: 'none',
                scrollTrigger: {
                    trigger: targetBottom.current,
                    start: '0px 100%',
                    end: '276px 100%',
                    scrub: 0.5,
                    markers: false,
                },
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <Jacket pad>
            <AnimatedLine isTop ref={targetTop} />
            <AnimatedLine ref={targetBottom} />

            <Row isExpanded>
                <Column small={12} medium={10} pushMedium={1} large={8} pushLarge={2} mpad>
                    <Content>
                        <Heading>Join us socially</Heading>


                        {/* TODO • Add URL links from Meta data in dato */}

                        <SocialList>
                            {fb && (
                                <SingleSocial>
                                    <Social target="_blank" rel="noopener noreferrer" href={fb}>
                                        <Icons>
                                            <Icon type="facebook" />
                                            <Icon type="facebook" />
                                        </Icons>
                                    </Social>
                                </SingleSocial>
                            )}

                            {tw && (
                                <SingleSocial>
                                    <Social target="_blank" rel="noopener noreferrer" href={tw}>
                                        <Icons>
                                            <Icon type="twitter" />
                                            <Icon type="twitter" />
                                        </Icons>
                                    </Social>
                                </SingleSocial>
                            )}

                            {li && (
                                <SingleSocial>
                                    <Social target="_blank" rel="noopener noreferrer" href={li}>
                                        <Icons>
                                            <Icon type="linkedin" />
                                            <Icon type="linkedin" />
                                        </Icons>
                                    </Social>
                                </SingleSocial>
                            )}
                        </SocialList>
                    </Content>
                </Column>
            </Row>
        </Jacket>
    );
}

export default NewsShare;