// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';
import { emStyles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`

`);

export const Picture = styled.div(props => css`
    position: relative;
    overflow: hidden;

    /* NOTE • Aspect ratio generator */
    width: 100%;
    padding-top: ${props.aspectHeight / (props.aspectWidth / 100)}%;
`);

export const Parallax = styled.picture(props => css`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;

    transform: scale(1.2) translateY(6%);


    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }
`);



export const Credits = styled.span(props => css`
    ${emStyles}
    margin-top: 2.4rem;
    color: ${props.theme.colors.brand.bc5o4};
`);
