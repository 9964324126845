// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, Em } from '@tackl';
import { emStyles, h4Styles, h2Styles } from '@tackl-type';
import { Link } from 'gatsby';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 10;
    ${props.theme.vh};

    padding: ${props.extraPad}rem 0 3.6rem;
    overflow: hidden;

    ${breakup.large`
        padding: ${props.extraPad}rem 0 8.4rem;
        min-height: 78rem;
    `}
`);

export const FakeSpacer = styled.div(props => css`
    position: relative;
    z-index: 11;
    pointer-events: none;

    ${props.theme.vh};
    min-height: 78rem;
`);


export const ArticleDetails = styled(Div)(props => css`
    position: relative;
    z-index: 2;

    display: flex;
    flex-flow: column;
    gap: 2.4rem;

    ${breakup.large`
        gap: 3.6rem;
    `}
`);

export const Content = styled(Div)(props => css`
    position: relative;
    z-index: 2;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`);


export const Back = styled(Link)(props => css`
    position: relative;
    z-index: 2;
    display: inline-flex;
    align-items: center;
    gap: 0.6rem;
    transform: translateX(-0.6rem);

    ${breakup.large`
        gap: 1.2rem;
    `}

    &:hover {
        svg { opacity: 0.6; }
    }

    svg {
        fill: none;
        stroke: ${props.theme.colors.brand.bc5};
        stroke-width: 0.3rem;
        transform: translateY(-0.1rem);
        transition: ${props.theme.easing.ease};
    }
`);

export const BackLabel = styled.span(props => css`
    ${emStyles}
    position: relative;
    color: transparent;
    transition: ${props.theme.easing.ease};
    overflow: hidden;
    

    &:before,
    &:after {
        content: attr(data-label);
        position: absolute;
        top: 0; left: 0;
        color: ${props.theme.colors.brand.bc5};
        transition: ${props.theme.easing.ease};
    }

    &:before {
        transform: translateY(0%) skewY(0deg);
    }

    &:after {
        transform: translateY(120%) skewY(-12deg);
    }

    &:hover {
        &:before,
        &:after {
            opacity: 0.6;
        }
        &:before {
            transform: translateY(-120%) skewY(12deg);
        }
        &:after {
            transform: translateY(0%) skewY(0deg);
        }
    }
`);

export const Background = styled(Div)(props => css`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 1;

    &:after {
        content: "";
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        background: ${props.theme.colors.global.black};
        opacity: 0.4;
    }

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }
`);

export const Parallax = styled.div(props => css`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    transform: scale(1.2) translateY(6%);
`);

export const Date = styled.div(props => css`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    time, span {
        ${emStyles}

        display: inline-flex;
        align-items: center;

        padding: 0.5rem 1.2rem 0.2rem;
        color: ${props.theme.colors.brand.bc5};
    }

    time {
        background: ${props.theme.colors.global.white20};
        border-radius: ${props.isFirst ? `1.8rem 0 0 1.8rem` : `1.8rem`};
        backdrop-filter: blur(2.4rem);
    }

    span {
        border-radius: 0 1.8rem 1.8rem 0;
        background: linear-gradient(to right,
            ${props.theme.colors.brand.bc2} 0%,
            ${props.theme.colors.brand.bc3} 100%);
    }
`);

export const Title = styled.h1(props => css`
    ${h2Styles}

    ${breakup.large`
        ${h4Styles}
    `}
`);

export const Extras = styled(Div)(props => css`
    display: flex;
    align-items: center;
    gap: 2.4rem;
`);

export const ScrollButtonPos = styled.div(props => css`
    display: none;
    position: absolute;
    bottom: 8.4rem;
    right: calc(8.333vw + 1.8rem);

    ${breakup.large`
        display: block;
    `}
`);

export const ReadingTime = styled(Em)(props => css`
    opacity: 0.6;
`);

