// Imports
// ------------
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

// Styles
// ------------
import { Jacket, Image, Name } from './styles';

// Component
// ------------
const Author = ({ name, pic }) => {
    return (
        <Jacket>
            <Image>
                <GatsbyImage image={pic.data} alt={pic.alt} />
            </Image>

            <Name>By {name}</Name>
        </Jacket>
    );
}

export default Author;