// Imports
// ------------
import React from 'react';
import { Row, Column } from '@waffl';
import { StructuredText } from 'react-datocms';

// Styles
// ------------
import { Jacket, StyledContent } from './styles';

// Component
// ------------
const ContentBlock = ({ content }) => {
    return (
        <Jacket>
            <Row isExpanded>
                <Column small={12} medium={10} pushMedium={1} large={8} pushLarge={2} mpad>
                    <StyledContent>
                        <StructuredText data={content} />
                    </StyledContent>
                </Column>
            </Row>
        </Jacket>
    );
}

export default React.memo(ContentBlock);