// Imports
// ------------
import React from 'react';
import { Row, Column } from '@waffl';
import ReactPlayer from 'react-player/lazy';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const VideoBlock = ({ url }) => {
    return (
        <Jacket>
            <Row isExpanded>
                <Column small={12} medium={10} pushMedium={1} mpad>
                    <div className='player-wrapper'>
                        <ReactPlayer
                            className='react-player'
                            url={url}
                            width={`100%`}
                            height={`100%`}
                            controls
                        />
                    </div>
                </Column>
            </Row>
        </Jacket>
    );
}

export default VideoBlock;