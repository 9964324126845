// Imports
// ------------
import React, { useEffect, useRef } from 'react';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Styles
// ------------
import { Jacket, Picture, Parallax, Credits } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const ImageBlock = ({ image, credits, creditsNeeded }) => {

    // NOTE • Refs
    const trigger = useRef();
    const target = useRef();

    // NOTE • Onload: scroll Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            gsap.to(target.current, {
                y: `-6%`,
                ease: 'none',
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '0% 100%',
                    end: '100% 0%',
                    scrub: 0.2,
                    markers: false,
                },
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <Jacket ref={trigger}>
            <Row isExpanded>
                <Column small={12} large={10} pushLarge={1} mpad>
                    <Picture aspectWidth={image.width} aspectHeight={image.height}>
                        <Parallax ref={target}>
                            <GatsbyImage image={image.data} alt={image.alt} />
                        </Parallax>
                    </Picture>
                    {creditsNeeded && <Credits>{credits}</Credits>}
                </Column>
            </Row>
        </Jacket>
    );
}

export default React.memo(ImageBlock);