// Imports
// ------
import React from 'react';
import NewsHero from "@parts/News/NewsHero";
import NewsBuilder from '@parts/News/NewsBuilder';
import NewsShare from '@parts/News/NewsShare';
import CallToAction from '@parts/CallToAction';
import Footer from '@parts/Footer';
import Seo from '@parts/Seo';
import { graphql } from 'gatsby';


// Component
// ------
const NewsPost = ({ data, pageContext }) => {
	return (
		<>
			<Seo
				title={data.post.heroTitle}
				desc={data.seo.seoMeta.desc}
			/>

			<NewsHero
				title={data.post.heroTitle}
				media={data.post.heroMedia}
				author={data.post.heroWritten}
				articleDate={data.post.posted.on}
				latestArticle={pageContext.postIndex}
				articleBuilderItems={data.post.newsBuilder}
			/>

			<NewsBuilder data={data.post.newsBuilder} />

			<NewsShare
				fb={data.global.fb}
				tw={data.global.tw}
				li={data.global.li}
			/>

			<CallToAction
				title={data.global.title}
			/>

			<Footer />
		</>
	);
}

export default NewsPost;


// GraphQL
// ------------
export const query = graphql`
	query NewsPostQuery($slug: String!) {
		# NOTE • SEO
		seo: datoCmsNewsPage {
			seoMeta {
				desc: description
			}
		}

		# NOTE • Call To Action
		global: datoCmsCallToAction {
			title
            fb: facebookUrl
            tw: twitterUrl
            li: linkedinUrl
		}

		allPosts: allDatoCmsNewsPost {
			nodes {
				id
			}
		}

		post: datoCmsNewsPost(slug: { eq: $slug }) {
			# ANCHOR • Seo
			seo: seoMeta {
				title
				desc: description
				card: twitterCard
				image {
					url
				}
			}

			# ANCHOR • Global
			slug
			posted: meta {
				on: firstPublishedAt
			}

			# ANCHOR • Hero
			heroTitle: title
			heroMedia: featuredMedia {
				alt
				data: gatsbyImageData(
					width: 1440
					height: 900
					placeholder: BLURRED
					layout: FULL_WIDTH
				)
			}
			heroWritten: writtenBy {
				name
				pic: authorPicture {
					alt
					data: gatsbyImageData(
						width: 72
						height: 72
						placeholder: BLURRED
						layout: FIXED
						imgixParams: { w: "72", h: "72", fit: "crop" }
					)
				}
			}

			# ANCHOR Content Builder
			newsBuilder {
				type: __typename

				... on DatoCmsVideo {
					uniqueId: originalId
					type: __typename
					videoUrl {
						url
						thumb: thumbnailUrl
					}
				}
				... on DatoCmsQuote {
					uniqueId: originalId
					type: __typename
						author
						hasAuthor
						quote
				}
				... on DatoCmsImage {
					uniqueId: originalId
					type: __typename
					image {
						alt
						width
						height
						aspect: resolutions {
							ratio: aspectRatio
						}
						data: gatsbyImageData(
							width: 1440
							height: 900
							placeholder: BLURRED
							layout: FULL_WIDTH
						)
					}
					creditsNeeded: imageCreditsNeeded
					credits: imageCredits

				}
				... on DatoCmsContentBlock {
					uniqueId: originalId
					type: __typename
					content {
						value
					}
				}
			}
		}
	}
`
