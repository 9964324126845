// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 10;
    background: ${props.theme.colors.brand.bc1};

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props.theme.space.small};

    ${breakup.medium`
        gap: ${props.theme.space.medium};
    `}

    ${breakup.large`
        gap: ${props.theme.space.large};
    `}
`);
